.row{
  margin: 0px;
  padding: 0px;
}
.campaign-box{
  border: 2px solid #3291ce;
  box-shadow: -5px 3px 1px 1px #92a8;
  padding: 5px;

}
.pd-0{
  padding: 0px !important;
}
.mg-0{
  margin: 0px ;
}
header nav{
  background: #F3F3F3;
  color: black;
}
body{
  background: #3290CD;
}
.theme-color{
  color: #3290CD;
}
.navbar{
  /* padding: 1rem 1rem; */
}
main{
  border: 0px solid red;
  background: #3290CD;
  color: #fff;

}
.container-box{
  border: 0px solid #f00;
  padding: 0px 25px;
  text-align: left;

}
.form-control{
  border-radius:0px !important;
}
label{
  margin: 0px;
}
.form-group {
  margin-bottom: 2vh;
}

/************************checkbox**************************/

INPUT[type=checkbox]:focus
{
  outline: 1px solid rgba(0, 0, 0, 0.2);
}

INPUT[type=checkbox]
{
  background-color: #DDD;
  border-radius: 2px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 17px;
  height: 17px;
  cursor: pointer;
  position: relative;
  top: 5px;
}

INPUT[type=checkbox]:checked
{
  background-color: #409fd6;
  background: #409fd6 url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 3px 3px no-repeat;
}

.invalid-feedback{
  color: #840024 !important; 
}

